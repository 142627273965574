.ps-tooltip {
  position: relative;
  display: inline-block;
}

.ps-tooltip .tooltiptext {
  width: 200px;
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -50%;
  opacity: 0;
  transition: opacity 1s;
}

.ps-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.ps-tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}