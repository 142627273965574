@font-face {
  font-family: 'Exo';
  src: url('/assets/fonts/Exo-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: lighter;
}

@font-face {
  font-family: 'Exo';
  src: url('/assets/fonts/Exo-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: light;
}

@font-face {
  font-family: 'Exo';
  src: url('/assets/fonts/Exo-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Exo';
  src: url('/assets/fonts/Exo-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Exo';
  src: url('/assets/fonts/Exo-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Exo';
  src: url('/assets/fonts/Exo-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: bold;
}

@font-face {
  font-family: 'Exo';
  src: url('/assets/fonts/Exo-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'Exo';
  src: url('/assets/fonts/Exo-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: bolder;
}

$font-family-sans-serif: "Exo", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$enable-gradients: true;
$enable-negative-margins: true;

// $blue: #0E71B8;
// $gray-100: #f2f4f7;
// $green: #0B8E36;
// $orange: #F9B233;

$primary: #706F6F;
$secondary: #253064;
$info: #b1c9eb;

$h1-font-size: 28px;
$h2-font-size: 20px;
$h3-font-size: 18px;
$h4-font-size: 14px;
$h5-font-size: 12px;
$h6-font-size: 10px;

$paragraph-font-size: 12px;
$paragraph-line-height: 16px;
  
$font-weight-lighter: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600; 
$font-weight-bolder: 700; 

$link-color: $secondary;
$link-decoration: none;
$link-font-size: $paragraph-font-size;
// $link-hover-color: $orange;
$link-hover-decoration: underline;

p, .p {
  font-size: $paragraph-font-size;
  line-height: $paragraph-line-height;
}

a, .link {
  font-size: $link-font-size;
  transition: all 0.2s ease;
}

.cursor-pointer {
  cursor: pointer;
}

section#hero {
  height: 720px;
}

section#hero #ankauf {
  background: url('/assets/images/auto-ankauf.jpg') center center;
  background-size: cover;
  
}

section#hero #verkauf {
  background: url('/assets/images/auto-verkauf.jpg') center center;
  background-size: cover;
}

#begutachtung-verkauf-fahrzeug {
  background: url('/assets/images/begutachtung-verkauf-fahrzeug.png') center center;
  background-size: cover;
  filter: grayscale(1);
}

.lorbeerblatt {
  background: url('/assets/images/lorbeerblatt.png') center right no-repeat;
  background-size: contain;
}

.inactive {
  opacity: 0.05;
}

.separator {
  width: 50px;
  height: 5px;
}

.landkreis-esslingen {
  background: url('/assets/images/landkreis-esslingen.png') top center/contain no-repeat;
}

.landkreis-goeppingen {
  background: url('/assets/images/landkreis-goeppingen.png') top center/contain no-repeat;
}

.landkreis-reutlingen {
  background: url('/assets/images/landkreis-reutlingen.png') top center/contain no-repeat;
}

.landkreis-stuttgart {
  background: url('/assets/images/landkreis-stuttgart.png') top center/contain no-repeat;
}

.landkreis-ulm {
  background: url('/assets/images/landkreis-ulm.png') top center/contain no-repeat;
}

.europa {
  background: url('/assets/images/europa.png') top center/contain no-repeat;
}

.h-500 {
  height: 500px;
}

@import "~bootstrap/scss/bootstrap";
@import "./tooltip.scss";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";


@include media-breakpoint-up(lg) {
  $h1-font-size: 34px;
  $h2-font-size: 36px;
  $h3-font-size: 32px;
  $h4-font-size: 18px;
  $h5-font-size: 16px;
  $h6-font-size: 12px;

  $paragraph-font-size: 16px;
  $paragraph-line-height: 24px;

  $link-font-size: $paragraph-font-size;

  h1, .h1 {
    font-size: $h1-font-size;
  }

  h2, .h2 {
    font-size: $h2-font-size;
  }

  h3, .h3 {
    font-size: $h3-font-size;
  }

  h4, .h4 {
    font-size: $h4-font-size;
  }

  h5, .h5 {
    font-size: $h5-font-size;
  }

  h6, .h6 {
    font-size: $h6-font-size;
  }

  p, .p {
    font-size: $paragraph-font-size;
    line-height: $paragraph-line-height;
  }

  a, .link {
    font-size: $link-font-size;
    transition: all 0.2s ease;
  }
}


@include media-breakpoint-up(xl) {
  $h1-font-size: 48px;
  $h4-font-size: 20px;
  
  h1, .h1 {
    font-size: $h1-font-size;
  }
  
  h4, .h4 {
    font-size: $h4-font-size;
  }
}